<template>
  <div
    v-show="visible"
    class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50 transition-opacity"
    role="dialog"
    aria-modal="true"
  >
    <div class="bg-white rounded-lg shadow-xl w-full max-w-sm mx-4 p-6 relative text-center">
      <h3 class="text-lg font-semibold text-gray-800 mb-4">Warning</h3>
      <p class="text-sm text-gray-600 mb-6">
        Updating the Survey Start or End date will adjust the Start/End dates of Question Sets as needed. Are you sure you want to
        continue?
      </p>
      <div class="flex space-x-4 justify-center">
        <button
          @click="confirm"
          class="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition"
          aria-label="Confirm survey update"
        >
          Yes, proceed
        </button>
        <button
          @click="cancel"
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition"
          aria-label="Cancel survey update"
        >
          Cancel
        </button>
      </div>
      <button
        @click="cancel"
        class="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition"
        aria-label="Close modal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
