<template>
  <div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
    <div class="bg-white p-6 rounded shadow-lg w-96">
      <h2 class="text-lg font-semibold mb-4">Add New Question Set</h2>
      <label class="block mb-2">Start Date (Optional):</label>
      <input
        v-model="questionSetData.start_datetime"
        type="datetime-local"
        class="mb-2 p-2 border w-full"
      />
      <label class="block mb-2">End Date (Optional):</label>
      <input
        v-model="questionSetData.end_datetime"
        type="datetime-local"
        class="mb-2 p-2 border w-full"
      />
      <div>
        <button @click="handleCreateQuestionSet" class="bg-green-500 text-white p-2 rounded mr-2">Add</button>
        <button @click="$emit('close')" class="bg-gray-300 text-gray-700 p-2 rounded">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    surveyId: {
      type: String,
      required: true,
    },
    surveyStartDate: {
      type: String,
      required: false,
    },
    surveyEndDate: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      questionSetData: {
        start_datetime: '',
        end_datetime: '',
      },
    };
  },
  methods: {
    ...mapActions('questionSets', ['createQuestionSet']),
    handleCreateQuestionSet() {
      const newQuestionSet = {
        survey_id: this.surveyId,
      };
      // Assign default dates if not provided
      if (this.questionSetData.start_datetime) {
        newQuestionSet.start_datetime = this.questionSetData.start_datetime;
      } else if (this.surveyStartDate) {
        newQuestionSet.start_datetime = this.surveyStartDate;
      }
      if (this.questionSetData.end_datetime) {
        newQuestionSet.end_datetime = this.questionSetData.end_datetime;
      } else if (this.surveyEndDate) {
        newQuestionSet.end_datetime = this.surveyEndDate;
      }
      this.createQuestionSet({ surveyId: this.surveyId, newQuestionSet });
      this.$emit('question-set-created');
      this.$emit('close');
    },
  },
};
</script>
