// store/SurveySystemResponsesModule.js
import axios from 'axios';
import { notify } from '@kyvg/vue3-notification';
import { deepEqual } from '@/utils/DataCompare';

const actions = {
  async createResponse({ commit }, { surveyId, questionSetId, questionId, newResponse }) {
    try {
      // Make a copy of newResponse to avoid mutating the original
      const responseToSend = { ...newResponse };
      // Remove the temporary UUID before sending to the server
      delete responseToSend.uuid;
  
      // Ensure question_uuid is included
      responseToSend.question_uuid = questionId;
  
      const response = await axios.post(`/questions/${questionId}/responses`, responseToSend);
      const createdResponse = response.data; // Adjust according to your API response structure
  
      // Merge newResponse with createdResponse
      const updatedResponse = {
        ...newResponse,        // Keep properties like 'text', 'is_single_choice'
        ...createdResponse,    // Override with properties from the server (like 'uuid')
        tempUuid: newResponse.uuid,
      };
  
      // Update the response in the store
      commit(
        'surveys/UPDATE_RESPONSE',
        { surveyId, questionSetId, questionId, response: updatedResponse },
        { root: true }
      );
  
      notify({ title: 'Success', text: 'Response created', type: 'success' });
    } catch (error) {
      // Rollback: Remove the optimistically added response
      commit(
        'surveys/DELETE_RESPONSE',
        { surveyId, questionSetId, questionId, responseId: newResponse.uuid },
        { root: true }
      );
      console.error('Error creating response:', error);
      notify({ title: 'Error', text: 'Failed to create response', type: 'error' });
    }
  },  
  
  async updateResponse({ commit, rootState }, { surveyId, questionSetId, questionId, updatedResponse }) {
    const originalResponse = findResponse(rootState.surveys.surveys, updatedResponse.uuid);
    if (!originalResponse) return;
    if (deepEqual(originalResponse, updatedResponse)) {
      // No changes detected
      return;
    }

    commit('surveys/UPDATE_RESPONSE', { surveyId, questionSetId, questionId, response: updatedResponse }, { root: true });

    try {
      await axios.put(`/responses/${updatedResponse.uuid}`, updatedResponse);
      notify({ title: 'Success', text: 'Response updated', type: 'success' });
    } catch (error) {
      commit('surveys/UPDATE_RESPONSE', { surveyId, questionSetId, questionId, response: originalResponse }, { root: true });
      notify({ title: 'Error', text: 'Failed to update response', type: 'error' });
    }
  },

  async deleteResponse({ commit, rootState }, { surveyId, questionSetId, questionId, responseId }) {
    // Find the response to delete for rollback in case of failure
    const responseToDelete = findResponse(rootState.surveys.surveys, responseId);
    if (!responseToDelete) return;

    // Optimistically remove the response from the store
    commit(
      'surveys/DELETE_RESPONSE',
      { surveyId, questionSetId, questionId, responseId },
      { root: true }
    );

    try {
      await axios.delete(`/responses/${responseId}`);
      notify({ title: 'Success', text: 'Response deleted', type: 'success' });
    } catch (error) {
      // Rollback: Re-add the response to the store
      commit(
        'surveys/ADD_RESPONSE',
        { surveyId, questionSetId, questionId, response: responseToDelete },
        { root: true }
      );
      console.error('Error deleting response:', error);
      notify({ title: 'Error', text: 'Failed to delete response', type: 'error' });
    }
  },
};

// Helper function to find a response
function findResponse(surveys, responseId) {
  for (const survey of surveys) {
    for (const questionSet of survey.question_sets) {
      for (const question of questionSet.questions) {
        const response = question.responses.find(r => r.uuid === responseId);
        if (response) {
          return response;
        }
      }
    }
  }
  return null;
}

export default {
  namespaced: true,
  actions,
};
