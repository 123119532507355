// store/SurveySystemQuestionSetsModule.js
import axios from 'axios';
import { notify } from '@kyvg/vue3-notification';

const actions = {
  async createQuestionSet({ commit }, { surveyId, newQuestionSet }) {
    // Assign a temporary UUID but do not overwrite the uuid property
    newQuestionSet.tempUuid = 'temp-' + Date.now();
    commit(
      'surveys/ADD_QUESTION_SET',
      {
        surveyId,
        questionSet: {
          ...newQuestionSet,
          uuid: newQuestionSet.tempUuid, // Use tempUuid as uuid for optimistic update
        },
      },
      { root: true }
    );
    try {
      const response = await axios.post('/question-sets', newQuestionSet);
      const createdQuestionSet = response.data.data;
      // Attach tempUuid to match and replace the temporary question set
      createdQuestionSet.tempUuid = newQuestionSet.tempUuid;
      commit(
        'surveys/UPDATE_QUESTION_SET',
        { surveyId, questionSet: createdQuestionSet },
        { root: true }
      );
      notify({ title: 'Success', text: 'Question set created', type: 'success' });
    } catch (error) {
      commit(
        'surveys/DELETE_QUESTION_SET',
        { surveyId, questionSetId: newQuestionSet.tempUuid },
        { root: true }
      );
      notify({ title: 'Error', text: 'Failed to create question set', type: 'error' });
    }
  },
  async updateQuestionSet({ commit, rootState }, { surveyId, updatedQuestionSet }) {
    const originalQuestionSet = findQuestionSet(rootState.surveys.surveys, updatedQuestionSet.uuid);
    if (!originalQuestionSet) return;
    commit('surveys/UPDATE_QUESTION_SET', { surveyId, questionSet: updatedQuestionSet }, { root: true });
    try {
      await axios.put(`/question-sets/${updatedQuestionSet.uuid}`, updatedQuestionSet);
      notify({ title: 'Success', text: 'Question set updated', type: 'success' });
    } catch (error) {
      commit('surveys/UPDATE_QUESTION_SET', { surveyId, questionSet: originalQuestionSet }, { root: true });
      notify({ title: 'Error', text: 'Failed to update question set', type: 'error' });
    }
  },
  async deleteQuestionSet({ commit }, { surveyId, questionSetId }) {
    // Optimistically remove the question set from the state
    commit(
      'surveys/DELETE_QUESTION_SET',
      { surveyId, questionSetId },
      { root: true }
    );
    try {
      await axios.delete(`/question-sets/${questionSetId}`);
      notify({ title: 'Success', text: 'Question set deleted', type: 'success' });
    } catch (error) {
      // Re-fetch the question set data if deletion failed
      try {
        const response = await axios.get(`/question-sets/${questionSetId}`);
        const questionSet = response.data.data;
        commit(
          'surveys/ADD_QUESTION_SET',
          { surveyId, questionSet },
          { root: true }
        );
      } catch (fetchError) {
        console.error('Error fetching question set after failed deletion:', fetchError);
      }
      notify({ title: 'Error', text: 'Failed to delete question set', type: 'error' });
    }
  },
  // ... other actions
};

// Helper function to find a question set
function findQuestionSet(surveys, questionSetId) {
  for (const survey of surveys) {
    const questionSet = survey.question_sets.find(qs => qs.uuid === questionSetId);
    if (questionSet) {
      return questionSet;
    }
  }
  return null;
}

export default {
  namespaced: true,
  actions,
};
