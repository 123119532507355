<template>
  <div class="p-3 bg-gray-50 rounded shadow-inner">
    <!-- Question Text Input -->
    <input
      v-model="editableQuestion.text"
      @blur="triggerQuestionUpdate"
      class="w-full bg-transparent border-none text-gray-800 font-medium"
    />
    <p class="text-sm text-gray-500 flex items-center">
      <!-- Type Input -->
      Type:
      <input
        v-model="editableQuestion.type"
        @blur="triggerQuestionUpdate"
        class="bg-transparent border-none ml-1"
      />
      <!-- Order Input -->
      Order:
      <input
        v-model.number="editableQuestion.order"
        @blur="triggerQuestionUpdate"
        class="bg-transparent border-none w-12 ml-1"
      />
      <!-- Multiple Choice Toggle Icon -->
      <button
        @click="toggleMultipleChoice"
        class="ml-2 focus:outline-none"
        :title="editableQuestion.is_multiple_choice ? 'Multiple Choice' : 'Single Choice'"
      >
        <!-- Use v-if and v-else to render icons directly -->
        <MultipleChoiceIcon
          v-if="editableQuestion.is_multiple_choice"
          class="h-6 w-6 text-gray-600"
        />
        <SingleChoiceIcon v-else class="h-6 w-6 text-gray-600" />
      </button>

      <button
        @click="confirmAndDeleteQuestion"
        class="ml-2 focus:outline-none text-red-600"
        title="Delete Question"
      >
        <TrashIcon class="h-6 w-6" />
      </button>
    </p>

    <!-- Responses Section -->
    <div v-if="question.responses" class="mt-2">
      <h6 class="text-sm font-semibold mb-1">Responses</h6>
      <div v-for="response in question.responses" :key="response.uuid" class="pl-4">
        <Response
          :response="response"
          :question="question"
          :surveyId="surveyId"
          :questionSetId="questionSetId"
          :questionId="question.uuid"
        />
      </div>
      <button
        @click="openCreateResponseModal"
        class="p-2 bg-purple-500 text-white rounded mt-2"
      >
        + Add Response
      </button>
    </div>

    <!-- Modal for Adding New Response -->
    <CreateResponse
      v-if="showCreateResponseModal"
      :questionId="question.uuid"
      :questionSetId="questionSetId"
      :surveyId="surveyId"
      @close="showCreateResponseModal = false"
      @response-added="handleResponseAdded"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Response from '@/components/Survey/ResponseComponent.vue';
import CreateResponse from '@/components/Survey/CreateResponse.vue';
import { CheckCircleIcon, XIcon, TrashIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Response,
    CreateResponse,
    MultipleChoiceIcon: CheckCircleIcon,
    SingleChoiceIcon: XIcon,
    TrashIcon,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    surveyId: {
      type: String,
      required: true,
    },
    questionSetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showCreateResponseModal: false,
      editableQuestion: { ...this.question },
    };
  },
  watch: {
    question: {
      handler(newQuestion) {
        this.editableQuestion = { ...newQuestion };
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('questions', ['updateQuestion', 'deleteQuestion']),
    triggerQuestionUpdate() {
      this.updateQuestionOptimistically();
    },
    async toggleMultipleChoice() {
      // Optimistically update the UI
      this.editableQuestion.is_multiple_choice = !this.editableQuestion.is_multiple_choice;

      // Prepare the updated question
      const updatedQuestion = { ...this.editableQuestion };

      // Call the updateQuestion action
      try {
        await this.updateQuestion({
          surveyId: this.surveyId,
          questionSetId: this.questionSetId,
          updatedQuestion: {
            ...updatedQuestion,
            question_set_uuid: this.questionSetId,
          },
        });
      } catch (error) {
        console.error('Error updating question:', error);
        // Error handling is done in the action; the store will rollback the change
      }
    },
    async updateQuestionOptimistically() {
      // Optimistically update the UI
      const updatedQuestion = { ...this.editableQuestion };

      try {
        await this.updateQuestion({
          surveyId: this.surveyId,
          questionSetId: this.questionSetId,
          updatedQuestion: {
            ...updatedQuestion,
            question_set_uuid: this.questionSetId,
          },
        });
      } catch (error) {
        console.error('Error updating question:', error);
        // Error handling is done in the action; the store will rollback the change
      }
    },
    async confirmAndDeleteQuestion() {
      // Confirm deletion with the user
      const confirmed = confirm('Are you sure you want to delete this question?');
      if (!confirmed) return;

      // Optimistically delete the question
      try {
        await this.deleteQuestion({
          surveyId: this.surveyId,
          questionSetId: this.questionSetId,
          questionId: this.question.uuid,
        });
      } catch (error) {
        console.error('Error deleting question:', error);
        // Error handling is done in the action; the store will rollback the change
      }
    },
    openCreateResponseModal() {
      this.showCreateResponseModal = true;
    },
    handleResponseAdded() {
      // Handle response added event if necessary
      this.$emit('response-added');
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
