<template>
  <div class="relative">
    <label class="block text-gray-700 text-sm font-bold mb-2">Select Survey</label>
    <select
      v-model="selectedSurveyId"
      @change="onSurveySelect"
      class="block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
    >
      <option value="" disabled>Select a survey</option>
      <option v-for="survey in surveys" :key="survey.uuid" :value="survey.uuid">
        {{ survey.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: "SurveyDropdown",
  data() {
    return {
      selectedSurveyId: ""
    };
  },
  computed: {
    ...mapState('surveys', {
      surveys: (state) => state.surveys
    })
  },
  async created() {
    await this.fetchSurveys();
  },
  methods: {
    ...mapActions('surveys', ['fetchSurveys']),
    onSurveySelect() {
      this.$emit("survey-selected", this.selectedSurveyId);
    }
  }
};
</script>

<style scoped>
/* Scoped styling for SurveyDropdown component */
</style>
