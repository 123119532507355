<template>
  <div class="flex items-center text-sm text-gray-600">
    <!-- Response Text Input -->
    • <input
      v-model="editableResponse.text"
      @blur="triggerResponseUpdate"
      class="bg-transparent border-none ml-1"
    />
    <!-- Single Choice Toggle Icon -->
    <button
      v-if="question && question.is_multiple_choice"
      @click="toggleSingleChoice"
      class="ml-2 focus:outline-none"
      :title="editableResponse.is_single_choice ? 'Single Choice' : 'Multiple Choice'"
    >
      <!-- Use v-if and v-else to render icons directly -->
      <CheckCircleIcon
        v-if="editableResponse.is_single_choice"
        class="h-5 w-5 text-gray-600"
      />
      <XIcon v-else class="h-5 w-5 text-gray-600" />
    </button>

    <!-- Delete Button -->
    <button
      @click="confirmAndDeleteResponse"
      class="ml-2 focus:outline-none text-red-600"
      title="Delete Response"
    >
      <TrashIcon class="h-5 w-5" />
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CheckCircleIcon, XIcon, TrashIcon } from '@heroicons/vue/outline'; // Adjust import path based on your Heroicons version

export default {
  name: 'ResponseComponent',
  props: {
    response: {
      type: Object,
      required: true,
    },
    surveyId: {
      type: String,
      required: true,
    },
    questionSetId: {
      type: String,
      required: true,
    },
    questionId: {
      type: String,
      required: true,
    },
  },
  components: {
    CheckCircleIcon,
    XIcon,
    TrashIcon,
  },
  data() {
    return {
      editableResponse: { ...this.response },
    };
  },
  computed: {
    ...mapGetters('surveys', ['getQuestionById']),
    question() {
      return this.getQuestionById(this.surveyId, this.questionSetId, this.questionId);
    },
  },
  methods: {
    ...mapActions('responses', ['updateResponse', 'deleteResponse']),
    triggerResponseUpdate() {
      this.updateResponseOptimistically();
    },
    async toggleSingleChoice() {
      // Optimistically update the UI
      this.editableResponse.is_single_choice = !this.editableResponse.is_single_choice;

      // Prepare the updated response
      const updatedResponse = { ...this.editableResponse };

      // Call the updateResponse action
      try {
        await this.updateResponse({
          surveyId: this.surveyId,
          questionSetId: this.questionSetId,
          questionId: this.questionId,
          updatedResponse: updatedResponse,
        });
      } catch (error) {
        console.error('Error updating response:', error);
        // Error handling is done in the action; the store will rollback the change
      }
    },
    async updateResponseOptimistically() {
      // Optimistically update the UI
      const updatedResponse = { ...this.editableResponse };

      try {
        await this.updateResponse({
          surveyId: this.surveyId,
          questionSetId: this.questionSetId,
          questionId: this.questionId,
          updatedResponse: updatedResponse,
        });
      } catch (error) {
        console.error('Error updating response:', error);
        // Error handling is done in the action; the store will rollback the change
      }
    },
    async confirmAndDeleteResponse() {
      // Confirm deletion with the user
      const confirmed = confirm('Are you sure you want to delete this response?');
      if (!confirmed) return;

      // Optimistically delete the response
      try {
        await this.deleteResponse({
          surveyId: this.surveyId,
          questionSetId: this.questionSetId,
          questionId: this.questionId,
          responseId: this.response.uuid,
        });
      } catch (error) {
        console.error('Error deleting response:', error);
        // Error handling is done in the action; the store will rollback the change
      }
    },
  },
  watch: {
    response: {
      handler(newResponse) {
        this.editableResponse = { ...newResponse };
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
