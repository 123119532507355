<template>
    <div>
        <div v-if="loading || balancing" class="flex items-center justify-center">
            <LoadingComponent />
        </div>
        <div v-else class="bg-gray-100 p-4 rounded shadow-md">
            <h2 class="text-lg font-bold">
                <input
                    v-model="editableSurvey.name"
                    @blur="triggerSurveyUpdate"
                    class="w-full bg-transparent border-none text-lg font-bold"
                />
            </h2>
            <textarea
                v-model="editableSurvey.description"
                @blur="triggerSurveyUpdate"
                class="w-full bg-transparent border-none text-gray-700"
            ></textarea>
            
            <!-- Start and End Date Inputs -->
            <div class="flex flex-col gap-4 mt-4">
                <div class="flex items-center gap-2">
                    <label for="start" class="w-16 text-sm text-gray-500">Start:</label>
                    <input
                        id="start"
                        type="datetime-local"
                        v-model="editableSurvey.start_datetime"
                        @blur="checkStartDateChange"
                        class="flex-1 border rounded p-2 text-sm"
                    />
                </div>
                <div class="flex items-center gap-2">
                    <label for="end" class="w-16 text-sm text-gray-500">End:</label>
                    <input
                        id="end"
                        type="datetime-local"
                        v-model="editableSurvey.end_datetime"
                        @blur="checkEndDateChange"
                        class="flex-1 border rounded p-2 text-sm"
                    />
                </div>
            </div>
            
            <!-- Confirmation Modal -->
            <SurveyConfirmationModal
                :visible="showConfirmationModal"
                @confirm="confirmDateChange"
                @cancel="cancelUpdate"
                :message="confirmationMessage"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SurveyConfirmationModal from './SurveyConfirmationModal.vue';

export default {
    name: 'SurveyDetails',
    components: { SurveyConfirmationModal },
    props: {
        survey: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editableSurvey: { ...this.survey },
            showConfirmationModal: false,
            confirmationMessage: '',
            pendingUpdate: null,
        };
    },
    computed: {
        ...mapState('surveys', ['loading', 'balancing']),
    },
    methods: {
        ...mapActions('surveys', ['updateSurvey']),
        checkStartDateChange() {
            const startDate = new Date(this.editableSurvey.start_datetime);
            const originalStartDate = new Date(this.survey.start_datetime);

            if (startDate > originalStartDate) {
                this.confirmationMessage =
                    'Updating the start date may adjust question set dates forward. Continue?';
                this.pendingUpdate = () => {
                    this.ensureEndAfterStart();
                    this.triggerSurveyUpdate();
                };
                this.showConfirmationModal = true;
            } else {
                this.ensureEndAfterStart();
                this.triggerSurveyUpdate();
            }
        },
        checkEndDateChange() {
            const endDate = new Date(this.editableSurvey.end_datetime);
            const originalEndDate = new Date(this.survey.end_datetime);

            if (endDate < originalEndDate) {
                this.confirmationMessage =
                    'Updating the end date may adjust question set dates backward. Continue?';
                this.pendingUpdate = () => {
                    this.ensureStartBeforeEnd();
                    this.triggerSurveyUpdate();
                };
                this.showConfirmationModal = true;
            } else {
                this.ensureStartBeforeEnd();
                this.triggerSurveyUpdate();
            }
        },
        ensureEndAfterStart() {
            const start = new Date(this.editableSurvey.start_datetime);
            const end = new Date(this.editableSurvey.end_datetime);

            if (start >= end) {
                const adjustedEnd = new Date(start);
                adjustedEnd.setDate(start.getDate() + 1);
                this.editableSurvey.end_datetime = adjustedEnd.toISOString().slice(0, 16);
            }
        },
        ensureStartBeforeEnd() {
            const start = new Date(this.editableSurvey.start_datetime);
            const end = new Date(this.editableSurvey.end_datetime);

            if (end <= start) {
                const adjustedStart = new Date(end);
                adjustedStart.setDate(end.getDate() - 1);
                this.editableSurvey.start_datetime = adjustedStart.toISOString().slice(0, 16);
            }
        },
        async triggerSurveyUpdate() {
        try {
            this.$store.commit('surveys/SET_LOADING', true); // Start loading spinner

            // Update the survey
            await this.updateSurvey(this.editableSurvey);

            // Re-fetch the updated survey data to reflect changes
            await this.$store.dispatch('surveys/fetchSurvey', this.editableSurvey.uuid);

            this.$store.commit('surveys/SET_LOADING', false); // Stop loading spinner
        } catch (error) {
            console.error('Error updating survey:', error);

            // Roll back changes on failure
            this.editableSurvey = { ...this.survey };
            this.$store.commit('surveys/SET_LOADING', false);
        }
        },
        confirmDateChange() {
            this.showConfirmationModal = false;
            if (this.pendingUpdate) {
                this.pendingUpdate();
                this.pendingUpdate = null;
            }
        },
        cancelUpdate() {
            this.showConfirmationModal = false;
            this.pendingUpdate = null;
            this.editableSurvey = { ...this.survey };
        },
    },
    watch: {
        survey: {
            handler(newSurvey) {
                this.editableSurvey = { ...newSurvey };
            },
            deep: true,
        },
    },
};
</script>
