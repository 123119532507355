<template>
  <div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
    <div class="bg-white p-6 rounded shadow-lg w-96">
      <h2 class="text-lg font-semibold mb-4">Add New Question</h2>
      <input v-model="questionData.text" placeholder="Question Text" class="mb-2 p-2 border w-full" />
      <input v-model="questionData.type" placeholder="Question Type" class="mb-2 p-2 border w-full" />
      <input v-model.number="questionData.order" placeholder="Order" type="number" class="mb-2 p-2 border w-full" />
      <label>
        <input type="checkbox" v-model="questionData.is_multiple_choice" class="mr-2" /> Multiple Choice
      </label>

      <div v-if="creating" class="flex items-center justify-center">
        <LoadingComponent />
      </div>
      <div v-else>
        <button @click="createQuestion" class="bg-green-500 text-white p-2 rounded mr-2" :disabled="creating">
          Add
        </button>
        <button @click="$emit('close')" class="bg-gray-300 text-gray-700 p-2 rounded">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CreateQuestion',
  props: {
    questionSetId: {
      type: String,
      required: true,
    },
    surveyUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
        questionData: {
            text: '',
            type: '',
            is_multiple_choice: false,
            order: 0,
            survey_uuid: this.surveyUuid,
            responses: [], // Include responses as an empty array
        },
      creating: false,
    };
  },
  methods: {
    ...mapActions('questions', { createQuestionAction: 'createQuestion' }),
    async createQuestion() {
      if (this.creating) return; // Prevent multiple clicks
      this.creating = true;

      // Assign a temporary UUID
      this.questionData.uuid = 'temp-' + Date.now();

      // Optimistically update the store
      this.$store.commit(
        'surveys/ADD_QUESTION',
        {
          surveyId: this.surveyUuid,
          questionSetId: this.questionSetId,
          question: this.questionData,
        },
        { root: true }
      );

      this.$emit('close'); // Close the dialog immediately

      try {
        await this.createQuestionAction({
          surveyId: this.surveyUuid,
          questionSetId: this.questionSetId,
          newQuestion: this.questionData,
        });
      } catch (error) {
        console.error('Error creating question:', error);
        // Rollback if needed
        this.$store.commit(
          'surveys/DELETE_QUESTION',
          {
            surveyId: this.surveyUuid,
            questionSetId: this.questionSetId,
            questionId: this.questionData.uuid,
          },
          { root: true }
        );
      } finally {
        this.creating = false;
      }
    },
  },
};
</script>
