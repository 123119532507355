<template>
  <div>
    <!-- Pagination Controls -->
    <div class="flex justify-between mb-4">
      <button @click="prevPage" :disabled="currentPage === 0" class="px-4 py-2 bg-gray-300 hover:bg-gray-400 disabled:opacity-50">Prev</button>
      <button @click="nextPage" :disabled="currentPage === calendarMonths.length - 1" class="px-4 py-2 bg-gray-300 hover:bg-gray-400 disabled:opacity-50">Next</button>
    </div>

    <!-- Single Month View -->
    <div v-if="calendarMonths.length > 0" class="mb-8">
      <h2 class="text-xl font-bold mb-2">
        {{ getMonthName(calendarMonths[currentPage].month) }} {{ calendarMonths[currentPage].year }}
      </h2>
      <div class="grid grid-cols-7 gap-px bg-gray-200">
        <!-- Weekday Headers -->
        <div v-for="(day, idx) in 7" :key="'weekday-' + idx" class="bg-white p-2 text-center font-semibold">
          {{ ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][idx] }}
        </div>

        <!-- Dates -->
        <div 
          v-for="(date, dateIndex) in calendarMonths[currentPage].dates" 
          :key="'date-' + dateIndex" 
          class="bg-white p-2 h-24 relative border border-gray-300"
        >
          <!-- Hour lines -->
          <div class="absolute inset-0 z-0">
            <div 
              v-for="hour in hourLines" 
              :key="'hour-' + hour" 
              class="absolute w-full border-t border-gray-200" 
              :style="{ top: (hour / 24 * 100) + '%' }"
            ></div>
          </div>

          <!-- Intervals -->
          <div class="absolute inset-0 z-10">
            <div
              v-for="(interval, intervalIndex) in getIntervalsForDate(date)"
              :key="'interval-' + intervalIndex"
              class="absolute w-full opacity-75"
              :style="{
                top: interval.top + '%',
                height: interval.height + '%',
                backgroundColor: getQuestionSetColor(interval.questionSet.uuid)
              }"
              :title="'Question Set: ' + interval.questionSet.name"
            ></div>
          </div>

          <!-- Day number -->
          <div 
            class="relative z-20 bg-white inline-block px-1 text-sm" 
            :class="{'text-gray-400': date.getMonth() !== calendarMonths[currentPage].month}"
          >
            {{ date.getDate() }}
          </div>
        </div>
      </div>
    </div>

    <!-- Legend -->
    <div>
      <h3 class="text-lg font-bold mt-4">Legend</h3>
      <div class="flex flex-wrap">
        <div v-for="qs in questionSets" :key="'legend-' + qs.uuid" class="flex items-center mr-4 mt-2">
          <div class="w-4 h-4 mr-2" :style="{ backgroundColor: getQuestionSetColor(qs.uuid) }"></div>
          <div>{{ qs.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SurveyIntervalsCalendar',
  props: {
    surveyStartDate: { type: String, required: true },
    surveyEndDate: { type: String, required: true },
    surveyUuid: { type: String, required: true },
  },
  data() {
    return {
      calendarMonths: [],
      currentPage: 0,
      hourLines: Array.from({ length: 24 }, (_, i) => i + 1),
      loading: true,
    };
  },
  computed: {
    ...mapGetters('surveys', ['getSurveyById', 'getQuestionSetColor']),
    survey() {
      return this.getSurveyById(this.surveyUuid);
    },
    questionSets() {
      return this.survey && this.survey.question_sets ? this.survey.question_sets : [];
    },
  },
  async created() {
    if (!this.survey) {
      await this.$store.dispatch('surveys/fetchSurvey', this.surveyUuid);
    }
    this.generateCalendarMonths();
    this.loading = false;
  },
  methods: {
    prevPage() {
      if (this.currentPage > 0) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.calendarMonths.length - 1) this.currentPage++;
    },
    generateCalendarMonths() {
      const start = new Date(this.surveyStartDate);
      const end = new Date(this.surveyEndDate);
      const months = [];

      let currentMonth = new Date(start.getFullYear(), start.getMonth(), 1);
      const endMonth = new Date(end.getFullYear(), end.getMonth(), 1);

      while (currentMonth <= endMonth) {
        const monthStart = new Date(currentMonth);
        const monthEnd = new Date(
          currentMonth.getFullYear(),
          currentMonth.getMonth() + 1,
          0
        );

        const dates = [];
        const firstDayOfWeek = new Date(monthStart);
        const dayOfWeek = firstDayOfWeek.getDay();
        firstDayOfWeek.setDate(firstDayOfWeek.getDate() - dayOfWeek);

        let currentDate = new Date(firstDayOfWeek);
        while (currentDate <= monthEnd || currentDate.getDay() !== 0) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }

        months.push({ year: currentMonth.getFullYear(), month: currentMonth.getMonth(), dates });
        currentMonth.setMonth(currentMonth.getMonth() + 1);
      }

      this.calendarMonths = months;
    },
    getIntervalsForDate(date) {
      const intervals = [];
      if (!this.questionSets.length) return intervals;

      const dayStart = new Date(date); dayStart.setHours(0,0,0,0);
      const dayEnd = new Date(date); dayEnd.setHours(23,59,59,999);

      this.questionSets.forEach((qs) => {
        if (!qs.intervals) return;
        qs.intervals.forEach((interval) => {
          const intervalStart = new Date(interval.start_datetime);
          const intervalEnd = new Date(interval.end_datetime);
          if (intervalEnd < dayStart || intervalStart > dayEnd) return;

          const effectiveStart = intervalStart < dayStart ? dayStart : intervalStart;
          const effectiveEnd = intervalEnd > dayEnd ? dayEnd : intervalEnd;
          const totalMinutes = 24 * 60;
          const startMinutes = effectiveStart.getHours()*60 + effectiveStart.getMinutes();
          const endMinutes = effectiveEnd.getHours()*60 + effectiveEnd.getMinutes();

          const topPercent = (startMinutes / totalMinutes) * 100;
          const heightPercent = ((endMinutes - startMinutes) / totalMinutes) * 100;

          intervals.push({ questionSet: qs, top: topPercent, height: heightPercent });
        });
      });

      return intervals;
    },
    getMonthName(monthIndex) {
      const monthNames = ['January','February','March','April','May','June','July','August','September','October','November','December'];
      return monthNames[monthIndex];
    },
  },
};
</script>
