<template>
  <div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
    <div class="bg-white p-6 rounded shadow-lg w-96">
      <h2 class="text-lg font-semibold mb-4">Add Response</h2>

      <input
        v-model="responseData.text"
        placeholder="Response Text"
        class="mb-2 p-2 border w-full"
      />
      <label class="flex items-center mb-2">
        <input
          type="checkbox"
          v-model="responseData.is_single_choice"
          class="mr-2"
        />
        Single Choice
      </label>

      <div v-if="creating" class="flex items-center justify-center">
        <LoadingComponent />
      </div>
      <div v-else>
        <button
          @click="createResponse"
          class="bg-blue-500 text-white p-2 rounded mr-2"
        >
          Add
        </button>
        <button
          @click="$emit('close')"
          class="bg-gray-300 text-gray-700 p-2 rounded"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CreateResponse',
  props: {
    questionId: {
      type: String,
      required: true,
    },
    questionSetId: {
      type: String,
      required: true,
    },
    surveyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      responseData: {
        text: '',
        is_single_choice: false,
        question_uuid: this.questionId, // Include question_uuid
      },
      creating: false,
    };
  },
  methods: {
    ...mapActions('responses', { createResponseAction: 'createResponse' }),
    async createResponse() {
      if (this.creating) return;
      this.creating = true;

      // Assign a temporary UUID
      this.responseData.uuid = 'temp-' + Date.now();

      // Ensure question_uuid is set
      this.responseData.question_uuid = this.questionId;

      // Optimistically update the store
      this.$store.commit(
        'surveys/ADD_RESPONSE',
        {
          surveyId: this.surveyId,
          questionSetId: this.questionSetId,
          questionId: this.questionId,
          response: this.responseData,
        },
        { root: true }
      );

      this.$emit('close'); // Close the dialog immediately

      try {
        await this.createResponseAction({
          surveyId: this.surveyId,
          questionSetId: this.questionSetId,
          questionId: this.questionId,
          newResponse: this.responseData,
        });
      } catch (error) {
        console.error('Error creating response:', error);
        // Rollback the optimistic update
        this.$store.commit(
          'surveys/DELETE_RESPONSE',
          {
            surveyId: this.surveyId,
            questionSetId: this.questionSetId,
            questionId: this.questionId,
            responseId: this.responseData.uuid,
          },
          { root: true }
        );
      } finally {
        this.creating = false;
      }
    },
  },

};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
