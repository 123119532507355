// store/SurveySystemQuestionsModule.js
import axios from 'axios';
import { notify } from '@kyvg/vue3-notification';
import { deepEqual } from '@/utils/DataCompare';

const actions = {
  async createQuestion({ commit }, { surveyId, questionSetId, newQuestion }) {
    try {
      const questionToSend = { ...newQuestion };
      delete questionToSend.uuid;
  
      const response = await axios.post(`/question-sets/${questionSetId}/questions`, questionToSend);
      const createdQuestion = response.data;
  
      // Merge newQuestion with createdQuestion
      const updatedQuestion = {
        ...newQuestion,        // Keep properties like 'order' and 'is_multiple_choice'
        ...createdQuestion,    // Override with properties from the server (like 'uuid')
        tempUuid: newQuestion.uuid,
      };
  
      // Ensure 'responses' exists
      if (!updatedQuestion.responses) {
        updatedQuestion.responses = [];
      }
  
      // Update the question in the store
      commit(
        'surveys/UPDATE_QUESTION',
        { surveyId, questionSetId, question: updatedQuestion },
        { root: true }
      );
  
      notify({ title: 'Success', text: 'Question created', type: 'success' });
    } catch (error) {
      commit(
        'surveys/DELETE_QUESTION',
        { surveyId, questionSetId, questionId: newQuestion.uuid },
        { root: true }
      );
      console.error('Error creating question:', error);
      notify({ title: 'Error', text: 'Failed to create question', type: 'error' });
    }
  },
  
  async updateQuestion({ commit, rootState }, { surveyId, questionSetId, updatedQuestion }) {
    const originalQuestion = findQuestion(rootState.surveys.surveys, updatedQuestion.uuid);
    if (!originalQuestion) return;
    originalQuestion.question_set_uuid = questionSetId;
    
    const isObjectsEqual = deepEqual(originalQuestion, updatedQuestion);
    console.log('isObjectsEqual, questions module:', isObjectsEqual);
    // Check if anything actually changed
    if (deepEqual(originalQuestion, updatedQuestion)) {
      // No changes detected, so skip network request and commit
      return;
    }

    // If changes are detected, do optimistic update
    commit('surveys/UPDATE_QUESTION', { surveyId, questionSetId, question: updatedQuestion }, { root: true });
    try {
      await axios.put(`/questions/${updatedQuestion.uuid}`, updatedQuestion);
      notify({ title: 'Success', text: 'Question updated', type: 'success' });
    } catch (error) {
      // Rollback on failure
      commit('surveys/UPDATE_QUESTION', { surveyId, questionSetId, question: originalQuestion }, { root: true });
      notify({ title: 'Error', text: 'Failed to update question', type: 'error' });
    }
  },

  async deleteQuestion({ commit, rootState }, { surveyId, questionSetId, questionId }) {
    // Find the question to delete for rollback in case of failure
    const questionToDelete = findQuestion(rootState.surveys.surveys, questionId);
    if (!questionToDelete) return;

    // Optimistically remove the question from the store
    commit(
      'surveys/DELETE_QUESTION',
      { surveyId, questionSetId, questionId },
      { root: true }
    );

    try {
      await axios.delete(`/questions/${questionId}`);
      notify({ title: 'Success', text: 'Question deleted', type: 'success' });
    } catch (error) {
      // Rollback: Re-add the question to the store
      commit(
        'surveys/ADD_QUESTION',
        { surveyId, questionSetId, question: questionToDelete },
        { root: true }
      );
      console.error('Error deleting question:', error);
      notify({ title: 'Error', text: 'Failed to delete question', type: 'error' });
    }
  },
};

// Helper function to find a question
function findQuestion(surveys, questionId) {
  for (const survey of surveys) {
    for (const questionSet of survey.question_sets) {
      const question = questionSet.questions.find(q => q.uuid === questionId);
      if (question) {
        return question;
      }
    }
  }
  return null;
}

export default {
  namespaced: true,
  actions,
};
