<template>
    <div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
        <div class="bg-white p-6 rounded shadow-lg w-96">
            <h2 class="text-lg font-semibold mb-4">Create New Survey</h2>
            <input v-model="surveyData.name" placeholder="Survey Name" class="mb-2 p-2 border w-full" />
            <textarea v-model="surveyData.description" placeholder="Description" class="mb-2 p-2 border w-full"></textarea>
            <input v-model="surveyData.start_datetime" type="datetime-local" class="mb-2 p-2 border w-full" />
            <input v-model="surveyData.end_datetime" type="datetime-local" class="mb-2 p-2 border w-full" />

            <button @click="createSurvey" class="bg-blue-500 text-white p-2 rounded mr-2">Create</button>
            <button @click="$emit('close')" class="bg-gray-300 text-gray-700 p-2 rounded">Cancel</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            surveyData: {
                name: '',
                description: '',
                start_datetime: '',
                end_datetime: '',
            },
        };
    },
    methods: {
        async createSurvey() {

            const temporarySurvey = { ...this.surveyData, uuid: Date.now().toString() };

            try {
                // Create a temporary optimistic survey with a UUID
                this.$store.commit('surveys/ADD_SURVEY', temporarySurvey);

                // Make the API call
                const response = await this.$http.post('/surveys', this.surveyData);

                // Update survey with full server data
                this.$store.commit('surveys/UPDATE_SURVEY', response.data);

                // Fetch the full list of surveys to refresh the dropdown
                await this.$store.dispatch('surveys/fetchSurveys');

                // Emit the survey-created event for feedback or reactivity
                this.$emit('survey-created');

                // Optional: Show a success toast notification
                this.$notify({ type: 'success', title: 'Survey Created', text: 'Survey created successfully.' });

                // Close the modal
                this.$emit('close');
            } catch (error) {
                console.error('Error creating survey:', error);

                // Rollback optimistic addition on error
                this.$store.commit('surveys/DELETE_SURVEY', temporarySurvey?.uuid);

                this.$notify({ type: 'error', title: 'Error', text: 'Failed to create survey.' });
            }
        },

    },
};
</script>
