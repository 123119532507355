<template>
    <div class="flex flex-col gap-2 w-full">
        <!-- Create Campaign Form -->
        <form v-on:submit.prevent="submitCampaign" v-gtag="{ category: 'Create', action: 'Create Campaign', label: 'submit campaign button', value: this.form }" class="bg-white w-full rounded-lg p-7 my-2">
            <div class="space-y-6 sm:space-y-5">
                <h2 class="text-xl font-medium underline"> Basic Info: </h2>
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-tsm:border-gray-200">
                    <label for="campaign-name" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Campaign Name *
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">

                        <input type="text" maxlength="50" name="campaign-name" v-model="form.name" id="campaign-name" required class="max-w-lg block w-full 
                        shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />

                    </div>
                </div>

                <!-- Ad Type Selection -->
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-tsm:border-gray-200">
                    <label for="ad-type" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Ad Type *
                    </label>
                    <div class="flex items-center justify-center">
                        <span @click="form.ad_type = 'standard'" class="options-picker border-l rounded-l-md p-0" :class="{'options-picker-active' : this.form.ad_type == 'standard'}"> Standard </span>
                        <span @click="form.ad_type = 'reward_video'" class="options-picker p-0" :class="{'options-picker-active' : this.form.ad_type == 'reward_video'}"> Reward Video </span>
                        <span @click="form.ad_type = 'gif_hud'" class="options-picker border-r rounded-r-md p-0" :class="{'options-picker-active' : this.form.ad_type == 'gif_hud'}"> Gif Hud </span>
                    </div>
                </div>

                <!-- Times Gif Hud Shown Forecasting (only if Gif Hud is selected) -->
                <div v-if="form.ad_type == 'gif_hud'" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-tsm:border-gray-200">
                    <label for="times-gif-hud-shown-forecasting" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Times Gif Hud Shown (Forecasting) *
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <input type="number" min="1" name="times-gif-hud-shown-forecasting" v-model.number="times_gif_hud_shown_forecasting" id="times-gif-hud-shown-forecasting" required class="max-w-lg block w-full 
                        shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                    </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-3">
                    <label for="start-date" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Start Date *
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <input type="date" name="start-date" id="start-date" required :min="$dateTodayFormatted()" v-model="form.start_date" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                    </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-3">
                    <label for="end-date" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        End Date *
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <input type="date" name="end-date" id="end-date" required v-model="form.end_date" :min="form.start_date" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                    </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-t sm:border-gray-200 sm:py-3">
                    <label for="campaign-cat" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Category *
                    </label>
                    <div class="flex flex-row gap-1">
                        <DropdownComponent
                        class="lg:max-w-lg sm:max-w-xs w-full"
                        selected="Select a Category" 
                        :items="all_categories" 
                        @user_select="setCampaignCategory"
                        :key="dropdown_refresher"/>
                    </div>
                </div>

                <h2 class="text-xl font-medium underline"> Targetting: </h2>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-3">
                    <label for="rate-limit-platform" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Platform *
                    </label>
                    <div class="flex flex-col gap-1">
                        <DropdownComponent
                            class="lg:max-w-lg sm:max-w-xs w-full"
                            selected="Select a Platform" 
                            :items="all_platforms" 
                            @user_select="setCampaignPlatformLimit"
                            :key="dropdown_refresher" />
                        <div class="flex flex-row gap-1 flex-wrap">
                            <div v-for="platform in selected_platforms" :key="platform" class="text-white bg-indigo-500 rounded-md px-2 py-1 cursor-pointer hover:bg-blue-300 hover:text-black" @click="removePlatform(platform)">
                                {{platform}}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-3">
                    <label for="rate-limit-country" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Country
                    </label>
                    <div class="flex flex-col gap-1">
                        <DropdownComponent
                            class="lg:max-w-lg sm:max-w-xs w-full"
                            selected="All Countries" 
                            :items="all_countries" 
                            display="name"
                            @user_select="setCampaignCountryLimit"
                            :key="dropdown_refresher"/>

                        <div class="flex items-center justify-center">
                            <span @click="this.include_countries = true" class="options-picker border-l rounded-l-md p-0" :class="{'options-picker-active' : this.include_countries == true}"> Include </span>
                            <span @click="this.include_countries = false" class="options-picker rounded-r-md p-0" :class="{'options-picker-active' : this.include_countries == false}"> Exclude </span>
                        </div>

                        <div class="flex flex-row gap-1 flex-wrap">
                            <div v-for="country in selected_countries" :key="country" class="text-white bg-indigo-500 rounded-md px-2 py-1 cursor-pointer hover:bg-blue-300 hover:text-black" @click="removeCountry(country)">
                                {{country.name}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-3">
                    <label for="rate-limit-language" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Language
                    </label>
                    <div class="flex flex-col gap-1">
                        <DropdownComponent
                            class="lg:max-w-lg sm:max-w-xs w-full"
                            selected="All Languages" 
                            :items="all_languages" 
                            display="name"
                            @user_select="setCampaignLanguageLimit"
                            :key="dropdown_refresher"/>

                        <div class="flex flex-row gap-1 flex-wrap">
                            <div v-for="language in selected_languages" :key="language" class="text-white bg-indigo-500 rounded-md px-2 py-1 cursor-pointer hover:bg-blue-300 hover:text-black" @click="removeLanguage(language)">
                                {{language.name}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-t sm:border-gray-200 sm:py-3">
                    <label for="accessible_by" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Select Users To Grant Access *
                    </label>
                    <div class="flex flex-col gap-1">
                        <DropdownComponent
                            class="lg:max-w-lg sm:max-w-xs w-full"
                            selected="Select Users" 
                            :items="all_users" 
                            display="name"
                            @user_select="addUserToCampaignPermissions"
                            :key="dropdown_refresher"/>
                        <div class="flex flex-row gap-1 flex-wrap">
                            <div v-for="user in selected_users" :key="user" class="text-white bg-indigo-500 rounded-md px-2 py-1 cursor-pointer hover:bg-blue-300 hover:text-black" @click="removeAccessForUser(user)">
                                {{user.name}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-tsm:border-gray-200">
                    <label for="frequency-cap-per-day" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Frequency Cap Per Day (forecasting)
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">

                        <input type="number" maxlength="50" name="frequency-cap-per=day" v-model="frequency_cap_per_day" id="frequency-cap-per-day" class="max-w-lg block w-full 
                        shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                    </div>
                </div>

                <h2 class="text-xl font-medium underline"> Assets: </h2>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-3">
                    <label for="rate-limit-platform" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Games *
                    </label>
                    <div class="flex flex-col gap-1">
                        <DropdownComponent
                            class="lg:max-w-lg sm:max-w-xs w-full"
                            selected="Select Games" 
                            :items="all_games" 
                            display="friendly_name"
                            @user_select="setCampaignGame"
                            :key="dropdown_refresher" />
                    </div>
                </div>

                <div class="flex flex-row gap-1 items-center justify-center flex-wrap">
                    <div v-for="game in selected_games" :key="game" class="relative flex flex-col items-center justify-center border border-gray-600 p-2 rounded-md break-word break-all md:h-60 md:w-48 h-48 w-36">
                        <span @click="removeGame(game)" class="p-3 font-semibold absolute top-0 right-0 w-5 h-5 m-1 text-sm rounded-full bg-gray-300 hover:bg-red-500 hover:text-white duration-200 cursor-pointer flex justify-center items-center">X</span>
                        <img class="w-24 h-24 md:w-40 md:h-40 object-contain bg-transparent overflow-hidden" :src="game.img_url" alt="image" draggable="false"/>
                        <span class="max-md:text-xs font-medium break-word first-letter:capitalize text-center"> {{game.friendly_name}} </span>
                        <a :href="`/manage-item/game/${game.uuid}`">
                            <CogIcon class="w-6 h-6 md:w-8 md:h-8 p-1 mt-1 lighter-gray duration-150 rounded-md text-black"/>
                        </a>
                    </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-3 sm:border-t sm:border-gray-200">
                    <label for="rate-limit-platform" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Creatives *
                    </label>
                    <div class="flex flex-col gap-1">
                        <DropdownComponent
                            class="lg:max-w-lg sm:max-w-xs w-full"
                            selected="Select Creatives" 
                            :items="all_creatives" 
                            display="user_defined_name"
                            @user_select="setCampaignCreative"
                            :key="dropdown_refresher" />
                    </div>
                </div>

                <div class="flex flex-row gap-1 items-center justify-center flex-wrap">
                    <div v-for="creative in selected_creatives" :key="creative" class="relative flex flex-col items-center justify-center border border-gray-600 p-2 rounded-md break-word md:h-60 md:w-48 h-48 w-36">
                        <span @click="removeCreative(creative)" class="p-3 font-semibold absolute top-0 right-0 w-5 h-5 m-1 text-sm rounded-full bg-gray-300 hover:bg-red-500 hover:text-white duration-200 cursor-pointer flex justify-center items-center">X</span>
                        <img class="w-24 h-24 md:w-40 md:h-40 object-contain bg-transparent overflow-hidden" :src="creative.thumbnail_url" alt="image" draggable="false"/>
                        <span class="max-md:text-xs font-medium break-word break-all first-letter:capitalize text-center"> {{creative.user_defined_name + creative.img_type}} </span>
                        <a :href="`/manage-item/creative/${creative.uuid}`">
                            <CogIcon class="w-6 h-6 md:w-8 md:h-8 p-1 mt-1 lighter-gray duration-150 rounded-md text-black"/>
                        </a>
                    </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-3 sm:border-t sm:border-b sm:border-gray-200">
                    <label for="rate-limit-platform" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Placements
                    </label>
                    <div class="flex flex-col gap-1">
                        <DropdownComponent
                            class="lg:max-w-lg sm:max-w-xs w-full"
                            selected="Select Placements" 
                            :items="game_selected_placements" 
                            display="friendly_name"
                            @user_select="setCampaignPlacement"
                            :key="dropdown_refresher" />
                    </div>
                </div>

                <div class="flex flex-row gap-1 items-center justify-center flex-wrap">
                    <div v-for="placement in selected_placements" :key="placement" class="relative flex flex-col items-center justify-center border border-gray-600 p-2 rounded-md break-word break-all md:h-60 md:w-48 h-48 w-36">
                        <span @click="removePlacement(placement)" class="p-3 font-semibold absolute top-0 right-0 w-5 h-5 m-1 text-sm rounded-full bg-gray-300 hover:bg-red-500 hover:text-white duration-200 cursor-pointer flex justify-center items-center">X</span>
                        <img class="w-24 h-24 md:w-40 md:h-40 object-contain bg-transparent overflow-hidden" :src="placement.thumbnail_url" alt="image" draggable="false"/>
                        <span class="max-md:text-xs font-medium break-word first-letter:capitalize text-center"> {{placement.friendly_name}} </span>
                        <a :href="`/manage-item/placement/${placement.uuid}`">
                            <CogIcon class="w-6 h-6 md:w-8 md:h-8 p-1 mt-1 lighter-gray duration-150 rounded-md text-black"/>
                        </a>
                    </div>
                </div>

                <div class="flex flex-col gap-4 w-full mt-4 items-center justify-center">
                    <div class="border border-gray-400 rounded-md p-4 flex flex-col gap-2 w-full" v-if="this.canSubmitForecast && forecast_data.length > 0">
                        <span class="text-lg font-medium">Forecasted Data:</span>
                        <ul class="sm:grid sm:grid-cols-2 flex flex-wrap gap-1">
                            <li class="flex text-gray-700 mb-2" v-for="forecast_item in forecast_data" :key="forecast_item.label"> 
                                <span class=""> {{ forecast_item.label }}:</span> <span class="font-medium text-black">&nbsp;{{ forecast_item.value }} </span>
                            </li>
                        </ul>
                    </div>

                    <div class="flex flex-row flex-wrap gap-4 justify-center">
                        <button class="btn-primary light-blue" 
                            @click.prevent="getImpressionsForecast"> 
                            Forecast Impressions 
                        </button>

                        <button v-on:submit.prevent="submitCampaign" class="btn-primary green">
                            Submit Campaign
                        </button>
                    </div>
                </div>
            </div>
        </form> 
    </div>
</template>

<script>
/* import ImagePicker from '@/components/ImagePickers/ImagePicker.vue' */
import AdvertForm from '@/classes/AdvertForm.js'
import { CogIcon } from '@heroicons/vue/outline'
export default {
    components: {
        CogIcon
    },
    data() {
        return {
            item_type: 'creatives',
            form: new AdvertForm(),
            all_platforms: ['pc', 'mobile', 'console'],
            selected_platforms: [],
            all_countries: [],
            selected_countries: [],
            all_languages: [],
            selected_languages: [],
            all_users: [],
            selected_users: [],
            all_categories: [],
            forecast_data: [],
            all_creatives: [],
            selected_creatives: [],
            all_games: [],
            selected_games: [],
            game_selected_placements: [],
            all_placements: [],
            selected_placements: [],
            dropdown_refresher: 0,
            include_countries: true,
            frequency_cap_per_day: null,
        }
    },
    methods: {
        submitCampaign() {
            if(!this.areAllVariablesSet())
                return

            const confirmString = 'Are you sure you want to submit this campaign?'
            Object.assign(this.form, {
                games: this.selected_games,
                creatives: this.selected_creatives,
                placements: this.selected_placements,
                accessible_by: this.selected_users,
                rate_limit_countries: this.selected_countries,
                include_countries: this.include_countries,
                rate_limit_languages: this.selected_languages,
                rate_limit_platforms: this.selected_platforms,
            })

            const toasts = {
                'success': 'Campaign Created Successfully',
                'error': 'Sorry there was an error creating the campaign',
                'confirm': confirmString
            }

            this.$http_request(this.$http.post, 'campaigns', toasts, this.form)
                .then(response => {
                    const campaignUUID = response.data
                    // Reset form and selected options
                    this.form = new AdvertForm();
                    this.dropdown_refresher++;
                    this.clearSelectedCreatives();
                    this.clearSelectedGames();
                    this.clearSelectedPlacements();
                    this.resetUserPermissions();
                    this.clearRateLimits();

                    this.emitter.emit("refreshCampaignNavbar")

                    // Navigate to the new campaign page
                    this.$router.push(`/manage/campaigns/${campaignUUID}`)
                })
                .catch(error => {
                    console.error(error.message);
                })
        },

        async getAllPlacements() {
            await this.$http_request(this.$http.get, '/placements')
                .then(({data}) => {
                    this.all_placements = data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        async getCreatives() {
            await this.$http_request(this.$http.get, '/creatives')
                .then(({data}) => {
                    this.all_creatives = data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        async getGames() {
            await this.$http_request(this.$http.get, '/games')
                .then(({data}) => {
                    this.all_games = data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        clearSelectedGames() {
            this.selected_games = []
            this.getGames()
        },

        clearSelectedCreatives() {
            this.selected_creatives = []
            this.getCreatives()
        },

        clearSelectedPlacements() {
            this.game_selected_placements = []
            this.selected_placements = []
        },

        getGamePlacements(game) {
            let filtered_placements = this.all_placements.filter(placement => 
                game.place_id === placement.place_id
            );
            this.selected_placements = [...this.selected_placements, ...filtered_placements];
        },

        removeGamePlacements(game) {
            this.selected_placements = this.selected_placements.filter(placement => 
                game.place_id !== placement.place_id
            );
            this.game_selected_placements = this.game_selected_placements.filter(placement => 
                game.place_id !== placement.place_id
            );
        },

        areAllVariablesSet() {
            if (Object.keys(this.selected_games).length === 0 && Object.keys(this.selected_creatives).length === 0) {
                this.$notify({type: 'error', text: 'Please Select At Least One Creative And One Game To Create a Campaign!'})
                return false
            }

            if (this.selected_platforms.length === 0) {
                this.$notify({type: 'error', text: 'Please Select At Least One Platform!'})
                return false
            }

            if (!('category' in this.form) || this.form.category === null) {
                this.$notify({type: 'error', text: 'Please Select A Category!'})
                return false
            }

            return true
        },

        getImpressionsForecast(){
            if(!this.canSubmitForecast) {
                const message = this.cantSubmitForecastReasons.join(', ')
                this.$notify({
                    title: 'Cannot Submit Forecast',
                    text: message,
                    type: 'error',
                })
                return
            }
            const toasts = {'success': 'Campaign Forecasted Successfully', 'error': 'Sorry there was an error forecasting the campaign'}    
            const request = {
                games: this.selected_games,
                placements: this.selected_placements,
                start_date: this.form.start_date,
                end_date: this.form.end_date,
                rate_limit_countries: this.selected_countries.map(country => country.alpha2),
                include_countries: this.include_countries,
                rate_limit_platforms: this.selected_platforms,
                languages: this.selected_languages,
                frequency_cap_per_day: this.frequency_cap_per_day,
                is_reward_video: this.form.is_reward_video, // Include is_reward_video in the request
                is_gif_hud_forecasting: false,
                times_gif_hud_shown_forecasting: 0
            }

            request.is_reward_video = this.form.ad_type === 'reward_video' ? true : false

            // If ad_type is 'gif_hud', include the additional parameters
            if (this.form.ad_type === 'gif_hud') {
                request.is_gif_hud_forecasting = true
                request.times_gif_hud_shown_forecasting = this.times_gif_hud_shown_forecasting
            }
            this.$http_request(this.$http.post, '/forecasting/create-campaign-forecast', toasts, request)
                .then((response) => {
                    this.forecast_data = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        setCampaignPlatformLimit(platform) {
            this.selected_platforms.push(platform)
            this.all_platforms = this.all_platforms.filter( el => el !== platform)
        },
        setCampaignCountryLimit(country) {
            this.selected_countries.push(country)
            this.all_countries = this.all_countries.filter( el => el !== country)
        },
        setCampaignLanguageLimit(language) {
            this.selected_languages.push(language)
            this.all_languages = this.all_languages.filter( el => el !== language)
        },
        addUserToCampaignPermissions(user) {
            this.selected_users.push(user)
            this.all_users = this.all_users.filter( el => el.email !== user.email)
        },
        setCampaignCreative(creative) {
            this.selected_creatives.push(creative)
            this.all_creatives = this.all_creatives.filter(el => el.uuid !== creative.uuid)
        },
        setCampaignGame(game) {
            this.selected_games.push(game)
            this.all_games = this.all_games.filter(el => el.uuid !== game.uuid)
            this.getGamePlacements(game)
        },
        setCampaignPlacement(placement) {
            this.selected_placements.push(placement)
            this.game_selected_placements = this.game_selected_placements.filter(el => el.uuid !== placement.uuid)
        },
        resetUserPermissions() {
            this.selected_users = []
            this.getAllUsers()
        },
        setCampaignCategory(category) {
            this.form['category'] = category
        },
        async getCountries(){
            return this.$http_request(this.$http.get, '/countries')
                .then(({data}) => {
                    this.all_countries = data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
        async getLanguages(){
            return this.$http_request(this.$http.get, '/languages')
                .then(({data}) => {
                    this.all_languages = data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
        creativeSelected(creatives) {
            this.form.creative_uuids = creatives
        },
        gameSelected(games) {
            this.form.game_uuids = games
        },
        removeAccessForUser(user) {
            this.selected_users = this.selected_users.filter( el => el.email !== user.email)
            this.all_users.push(user)
            this.all_users.sort((a, b) => a.name.localeCompare(b.name))
        },
        removeGame(game) {
            this.selected_games = this.selected_games.filter( el => el.friendly_name !== game.friendly_name)
            this.all_games.push(game)
            this.all_games.sort((a, b) => a.friendly_name.localeCompare(b.friendly_name))

            this.selected_placements = this.selected_placements.filter(placement => 
                placement.place_id !== game.place_id
            )
            this.removeGamePlacements(game)

            this.dropdown_refresher++
        },
        removePlacement(placement) {
            this.selected_placements = this.selected_placements.filter( el => el.uuid !== placement.uuid)
            this.game_selected_placements.push(placement)
            this.game_selected_placements.sort((a, b) => a.place_id.localeCompare(b.place_id))
            this.dropdown_refresher++
        },
        removeCreative(creative) {
            this.selected_creatives = this.selected_creatives.filter( el => el.uuid !== creative.uuid)
            this.all_creatives.push(creative)
            this.all_creatives.sort((a, b) => b.created_at.localeCompare(a.created_at))
            this.dropdown_refresher++
        },
        removePlatform(platform) {
            this.selected_platforms = this.selected_platforms.filter( el => el !== platform)
            this.all_platforms.push(platform)
        },
        removeCountry(country) {
            this.selected_countries = this.selected_countries.filter( el => el !== country);
            this.all_countries.push(country);
            this.all_countries.sort((a, b) => {
                if (a.name === 'United States of America') return -1;
                if (b.name === 'United States of America') return 1;
                return a.name.localeCompare(b.name);
            });
        },
        removeLanguage(language) {
            this.selected_languages = this.selected_languages.filter( el => el !== language)
            this.all_languages.push(language)
            this.all_languages.sort((a, b) => a.name.localeCompare(b.name))
        },
        async getAllUsers() {
            await this.$http.get('/users/all')
                .then( ({data}) => {
                    this.all_users = data
                    this.removeUserFromAllUsers()
                })
        },
        async removeUserFromAllUsers() {
            await this.$http.get('/user')
                .then( ({data}) => {
                    this.all_users = this.all_users.filter(user => user.id !== data.id);
                })
        },
        clearRateLimits() {
            this.selected_countries = []
            this.selected_languages = []
            this.selected_platforms = []
            this.all_platforms = ['pc', 'mobile', 'console']
            this.getCountries()
            this.getLanguages()
        }
    },
    computed: {
        cantSubmitForecastReasons(){
            let reasons = []
            
            if(! this.form.start_date) reasons.push('No Start Date')
            if(! this.form.end_date) reasons.push('No End Date')
            if(! Object.keys(this.selected_games).length > 0) reasons.push('No Games Selected')

            return reasons
        },
        canSubmitForecast(){
            if(this.cantSubmitForecastReasons.length > 0) return false
            else return true
        }
    },
    async created() {
        await this.getAllUsers()
        await this.getCountries()
        await this.getLanguages()
        await this.getCreatives()
        await this.getGames()
        await this.getAllPlacements()

        this.all_categories = this.$store.getters['getCampaignCategories']
    }
}
</script>

